import { Platform } from '@angular/cdk/platform';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ApiService } from '@core/api.service';
import { HolidaysService } from '@services/common/holidays.service';
import { CrudService } from 'collection';
import { FiltersStoreService } from '@core/filters-store.service';
import { AuthService } from '@features/auth/auth.service';
import { FilterTypeFields } from '@features/mobile-scheduler/filterTypes';
import { SchedulerService } from '@features/scheduler/scheduler.service';
import { WorkDetailTooltipComponent } from '@features/scheduler/work-scheduler/work-detail-tooltip/work-detail-tooltip.component';
import { TimeService } from '@services/common/time.service';
import { FilterStorageModel } from '@shared/interfaces/filtersStore.model';
import { Collection, CollectionModel } from 'collection';
import { format } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PeriodTypes } from '@shared/components/scheduler-period-picker/filterTypes';
import { MobileSchedulerTypesEnum } from '../mobile-scheduler-types.enum';
import { updateEvents } from '@features/scheduler/employee-work-scheduler/day-view';

import { legacyParse, convertTokens } from '@date-fns/upgrade/v2';

@Component({
  selector: 'mobile-my-scheduler',
  templateUrl: './mobile-my-scheduler.component.html',
  styleUrls: ['./mobile-my-scheduler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileMySchedulerComponent implements OnChanges, OnInit, OnDestroy {
  @Input() filters: FilterStorageModel;
  @Input() timesheetDays: Array<any> = [];
  worksCollection: CollectionModel;
  timeSheetData = {};
  timesheetDay = null;
  minWidthRow: number;
  filterTypesEnum = FilterTypeFields;
  holidaysObj: { [key: string]: boolean } = {};

  user_id: number;
  today = format(legacyParse(new Date()), convertTokens('YYYY-MM-DD'));

  private destroyed$ = new Subject();
  private checkScrolling$ = new BehaviorSubject(null);

  @ViewChild('firstWork', { static: false }) firstWork: ElementRef;

  get isDayView(): boolean {
    return this.filters.get(this.filterTypesEnum.Period) === PeriodTypes.Day;
  }

  dayHours = Array(24)
    .fill(0)
    .map((e, index) => (index <= 9 ? `0${index}:00` : `${index}:00`));

  identifyDay(index, item) {
    return item.name;
  }

  openTooltip(elem: WorkDetailTooltipComponent) {
    elem.menuTrigger.openMenu();
    elem.open();
  }

  onDeleteWorkHandler(data): void {
    this.worksCollection.search();
  }

  onShowDetailsHandler(work): void {
    const messageObj = {
      title: 'showDetails',
      body: {
        work_id: work.id
      }
    };

    if (this._platform.IOS) {
      // @ts-ignore
      window?.webkit?.messageHandlers?.jsShowDetailsHandler.postMessage(messageObj);
    } else if (this._platform.ANDROID) {
      const stringVal = JSON.stringify(messageObj);
      // @ts-ignore
      window?.AndroidJsBridge?.showWorkDetails(stringVal);
    }
  }

  initHolidays(): void {
    const from = this.filters.get(this.filterTypesEnum.FromDate);
    const to = this.filters.get(this.filterTypesEnum.ToDate);

    this._holidaysService
      .getHolidays({ from, to })
      .pipe(
        take(1),
        map((list) => (this.holidaysObj = list)),
        map(() => this._cdRef.markForCheck())
      )
      .subscribe();
  }

  private initTimeSheetData(data: any): Observable<boolean> {
    this.timeSheetData = data.length !== 0 ? data : [];
    this.worksCollection.items = data;
    this.worksCollection.detectChanges();

    if (this.isDayView) {
      const date = this.filters.get(this.filterTypesEnum.FromDate);

      const worksForDay = this.timeSheetData[this.user_id]?.works[date];
      if (worksForDay) {
        this.timeSheetData[this.user_id].works[date] = worksForDay.map((item) => {
          return {
            ...item,
            top: this.setTop(item.from_time),
            height: item.duration / 60,
            start: this.setTop(item.from_time),
            end: item.duration / 60 + this.setTop(item.from_time)
          };
        });

        const [arr, maxLeft] = updateEvents(this.timeSheetData[this.user_id].works[date], {
          listPadding: 16,
          defaultLeft: 50
        });
        this.minWidthRow = maxLeft;
      }
    }

    return of(true);
  }

  private setTop(from_time: string): number {
    const [hours, minutes] = from_time.split(':');
    const min = 60 * +hours + +minutes;
    return min;
  }

  private initTimesheetCollection() {
    this.worksCollection.onLoad$
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((data) => this.initTimeSheetData(data))
      )
      .subscribe();
  }

  private initTimesheetDayView() {
    if (this.isDayView) {
      this.timesheetDay = this.timesheetDays[0];
    }
    this.checkScrolling$.next(true);
  }

  private initFilterSubscription() {
    this.filters.changed
      .pipe(
        takeUntil(this.destroyed$),
        tap((params) => {
          if (params?.fromTime && params?.toTime) {
            this.initHolidays();
          }
        }),
        filter((params) => !!params && this.filters.get(this.filterTypesEnum.View) === MobileSchedulerTypesEnum.MySchedule),
        map(() => {
          this.worksCollection
            .setParams('user_id[]', this._auth.user?.id)
            .setParams('from_time', this.filters.get(this.filterTypesEnum.FromDate))
            .setParams('to_time', this.filters.get(this.filterTypesEnum.ToDate))
            .search();
        })
      )
      .subscribe();
  }

  private checkScrollingSubscription() {
    this.checkScrolling$
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(1500),
        filter((val) => !!val && !!this.firstWork)
      )
      .subscribe(() => this.firstWork.nativeElement.scrollIntoView({ block: 'start', behavior: 'smooth' }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.timesheetDays.currentValue) {
      this.initTimesheetDayView();
    }
  }

  ngOnInit() {
    this.checkScrollingSubscription();
    this.initFilterSubscription();
    this.initTimesheetCollection();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.worksCollection.clear();
  }

  constructor(
    private _filtersStore: FiltersStoreService,
    private _api: ApiService,
    private _crud: CrudService,
    private _cdRef: ChangeDetectorRef,
    private _auth: AuthService,
    private _timeService: TimeService,
    private _schedulerService: SchedulerService,
    private _holidaysService: HolidaysService,
    public _platform: Platform
  ) {
    this.user_id = this._auth.user?.id;

    this.worksCollection = new Collection({
      api: this._crud.createEntity({ name: 'work' }),
      params: {
        expand: 'client,project',
        is_published: true
      }
    });
  }
}
